// 客户api
import api from '@/service/httpServer'
import { BaseConfig } from '@/config/storage'
import Cache from "@/cache";

interface Response {
  projectIds: Project[];
  user: User;
}

interface User {
  appid: string;
  id: string;
  roleid: string;
  transferRole: string;
  mobile: string;
}
interface Project {
  projectname: string;
  projectid: string;
  projectCode: string;
  projectIdErp: string;
}

// 获取项目
export async function getProjectList(): Promise<[Response, Error | null]> {
  const ret = await api.get(`/qc-webapp/m/qw/user/role?corpId=${Cache().corpId()}&userId=${Cache().userId()}`, {
    openId: Cache().openId() || '',
    passport: Cache().passPort() || '',
  })
  if (ret.msg != 'success') {
    return [ret.data, new Error(`The interface request failed msg = ${ret.msg}`)]
  }

  if (ret.code != 0) {
    return [ret.data, new Error(`The interface request failed  code = ${ret.code}`)]
  }
  return [ret.data, null]
}