const FAKE_DATA = new Map();
// newhope 刘杰
FAKE_DATA.set('newhope_lj', {
  openId: 'oFUZO5wkZxD4xHhSfBlZuKmchX30',
  passPort: '8e9c516b1143e2b47c361d6a2eede162',
  corpId: 'wpO3ZZCAAAswFIR0gItOQRJec59R838',
  qwUserId: 'woO3ZZCAAA2gfOpV-3PDXlRx7XlIZG0w',
});

// newhope 杨帆
FAKE_DATA.set('newhope_yf', {
  openId: 'oW5brtyS52_7IrrUiZ-hBynzhuOA',
  passPort: '2aca2374a3e5929fb0474bd7e7d6628a',
  corpId: 'wpO3ZZCAAA-AJ8BPszNSZMfOlD_M36WA',
  qwUserId: 'woO3ZZCAAAUB_7lErMPRhWCeTgkxioVw',
});

// newhope 杨帆
FAKE_DATA.set('newhope_hgy', {
  openId: 'oFUZO54dHj3gyeUULSyOYo5-jw7U',
  passPort: '5a47a3dc173ca79301e01f90c8defb31',
  corpId: 'ww6b916badbf80428f',
  qwUserId: 'HuangGangYu',
});

export default FAKE_DATA
