const Keys = {
  fake: 'fake',
  wxgzhOpenid: 'openid',
  wxgzhPassport: 'passport',
  wxgzhCropid: 'corpId',
  wxgzhQwUserId: 'qwUserId',
  userRole: 'userRole',
}

const devConfig = {
  // apiBaseURL: "http://10.53.1.14:9092/",
  apiBaseURL: "https://tmarketingapi.newhope.cn",
  // CorpId: "wx01f0b018d4951434",
  CorpId: "ww6b916badbf80428f",
  AppId: "1000083",
  xiWangYunAppletId: "wx2820b54fabb451f4",
  marketingUrl: 'https://tmarketingapi.newhope.cn/marketies/qwdesk'
}

const proConfig = {
  // apiBaseURL: "https://tmarketingapi.newhope.cn", // 测试环境
  apiBaseURL: "https://marketingapi.newhope.cn", // 正式环境
  CorpId: "ww6b916badbf80428f",
  AppId: "1000083",
  xiWangYunAppletId: "wxd783c47e55dce1de",
  // marketingUrl: 'https://tmarketingapi.newhope.cn/marketies/qwdesk' // 测试环境
  marketingUrl:'https://marketing.newhope.cn/marketies/qwdesk' // 正式环境
}

const BaseConfig = import.meta.env.DEV ? devConfig : proConfig

export {
  Keys,
  BaseConfig
}