import { NotificationCenter, Event } from "../nc/index";
import type { Class } from "../nc/Class";
let nc_ = new NotificationCenter();

// 项目变更事件
export class ProjectChangeEvent extends Event { }
export class ProjectLoadEvent extends Event { }

// 函件状态变更事件
export class LetterStatusChangeEvent extends Event { }
// 跟进动作事件
export class FollowUpChangeEvent extends Event { }

export function registerObServerEvent(that: object | symbol, eventName: Class<Event>, callback: Function) {
  nc_.register(that, eventName, async (event: Event) => {
    callback(event);
  });
}

export function unRegisterObServerEvent(that: object | symbol, eventName: Class<Event>) {
  nc_.unRegister(that, eventName);
}

export function unregisterAllObServerEvent(that: object | symbol) {
  nc_.unRegisterAll(that);
}

export function postObServerEvent(event: Event) {
  nc_.post(event);
}
