import Cache from "@/cache";
import { getUserInfo } from '@/api/common/authApi';
const baseUrl = "https://repay.xinxiwangdichan.com";
const oauth2 = "https://open.weixin.qq.com/connect/oauth2/authorize";
const CorpId = "ww6b916badbf80428f";

// 线上环境
async function init() {
  // 获取重定向回来的地址上的 code
  let code = getUrlParam('code');
  console.log('--------pro---init-----', window.location);
  if (!code) {
    loadUserCode();
  } else {
    await loadUserInfo(code);
  }
}

/**
 * 用户登录 step1:
 *   重定向到用户授权页面，获取临时code 该code 用于下一步中获取用户的 access_token 和 openId
 */
function loadUserCode() {
  console.log('-----pro---loadUserCode--------');
  let pathname = window.location.pathname;
  let redirect_uri = "";
  if (!pathname || pathname == '/') {
    redirect_uri = encodeURIComponent(baseUrl);
  } else {
    redirect_uri = encodeURIComponent(window.location.href);
  }
  let auth_url = `${oauth2}?appid=${CorpId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
  window.location.href = auth_url;
}


// 获取 url 参数的方法
function getUrlParam(name: string) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  let r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return '';
}

/**
 * 根据 corpId & corpSecret 获取 access_token
 * @param code 微信授权回调时的code
 */
async function loadUserInfo(code: string) {
  console.log('pro auth loadUserInfo code: ', code);
  const userId = Cache().openId();
  if (userId) return console.log('pro auth loadUserInfo userId: ', userId);
  // todo 调用后端接口，获取用户基本信息。
  return;
  const [ret, err] = await getUserInfo(code);
  if (err || !ret) return console.error('pro auth loadUserInfo error: ', err);
  // 公众号openid
  Cache().setOpenId(ret.openId);
  // 公众号passport
  Cache().setPassPort(ret.passport);
  // 企业微信openUserId
  Cache().setCorpId(ret.corpId);
  Cache().setUserId(ret.userId);
}

export default init;