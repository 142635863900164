import { createSSRApp } from "vue";
import App from "./App.vue";
// 引入 vant-ui 组件
import { useVantUi } from './vant-ui';
// 全局注册返回按钮组件
import GoBack from '@/components/GoBack.vue';
import EmptyComp from '@/components/empty.vue';
import Refresh from '@/components/refresh.vue';
import MoreBtn from '@/components/moreBtn.vue';
import LoadingComp from '@/components/loading.vue';
function createApp() {
  const app = createSSRApp(App);
  app.component('GoBack', GoBack);
  app.component('Refresh', Refresh);
  app.component('EmptyComp', EmptyComp);
  app.component('MoreBtn', MoreBtn);
  app.component('LoadingComp', LoadingComp);
  useVantUi(app);
  return {
    app,
  };
}

export {
  createApp
}
