<template>
  <view @click="callback" class="back-home" :style="{ bottom: bottom + 'px', left: left + 'px' }" @touchstart="start"
    @touchmove.prevent="move" @touchend="end">
    <image width="100%" height="100%" src="@/static/img/return.png" />
  </view>
</template>
<script setup lang="ts">
import { ref } from 'vue';

let xy = uni.getStorageSync("xy");
let bottom = ref(112);
let left = ref(0);
let pageX = ref(0);
let pageY = ref(0);
if (xy) {
  bottom.value = xy.bottom || bottom;
  left.value = xy.left || left;
}

const props = defineProps({
  backUrl: String,
  selfJump: Function,
})

function callback() {
  if (props.selfJump) {
    props.selfJump();
    return;
  }
  if (props.backUrl) {
    uni.redirectTo({ url: props.backUrl });
    return;
  }
  uni.navigateBack({ delta: 1 });
}

function start(e: any) {
  let page = e.changedTouches[0];
  pageX.value = page.pageX;
  pageY.value = page.pageY;
};

function move(e: any) {
  let page = e.changedTouches[0];
  let y = page.pageY - pageY.value;
  pageX.value = page.pageX;
  pageY.value = page.pageY;
  left.value = 0;
  if (bottom.value - y > 530) {
    bottom.value = 530;
  };
  if (bottom.value - y < 530 && bottom.value - y >= 80) {
    bottom.value = bottom.value - y;
  };
  if (bottom.value - y < 80) {
    bottom.value = 80;
  };

  uni.setStorageSync("xy", {
    left: left.value,
    bottom: bottom.value,
  });
};

function end(e: any) {
  let page = e.changedTouches[0];
};
</script>
<style lang="scss" scoped>
.back-home {
  width: 102rpx;
  height: 100rpx;
  position: fixed;
  z-index: 999 !important;

  image {
    width: 100%;
    height: 100%;
  }
}
</style>