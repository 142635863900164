import axios from 'axios';
import { BaseConfig } from '@/config/storage'
// 线上环境配置axios.defaults.baseURL

//请求拦截器
axios.interceptors.request.use(
  config => {
    // 域名代理设置
    if (new RegExp('^\/.*$').test(<string>config.url)) {
      config.baseURL = BaseConfig.apiBaseURL;
    }
    config.headers['Access-Control-Allow-Credentials'] = true;
    config.headers['Access-Control-Allow-Origin'] = '*';
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//响应拦截器即异常处理
axios.interceptors.response.use(
  response => {
    if (response.data.code === 0 || response.data.code === 200) {
      return Promise.resolve(response.data);
    } else {
      console.error(response.data.message || response.data.msg || response.data.errMsg);
      uni.showToast({ title: (response.data.message || response.data.msg || response.data.errMsg), icon: "error" });
      return Promise.reject(response.data.message || response.data.msg || response.data.errMsg);
    }
  },
  err => {
    if (err && err.response) {
      switch (err.response.code) {
        case 400:
          err.message = '错误请求';
          break;
        case 401:
          err.message = '未授权，请重新登录';
          break;
        case 403:
          err.message = '没有访问权限，拒绝访问';
          break;
        case 404:
          err.message = '请求错误,未找到该资源';
          break;
        case 405:
          err.message = '请求方法未允许';
          break;
        case 500:
          err.message = err.response.data.message || '服务器端出错';
          break;
        default:
          err.message = `连接错误${err.response.msg}`;
      }
    } else {
      err.message = '连接到服务器失败';
    }
    if (err.response.code !== 401) {
      console.error(err.message || err.response.msg);
      uni.showToast({ title: (err.message || err.response.msg), icon: "error" });
    }
    return Promise.reject(err.response);
  }
);

/**
 * @description: 下载文件
 * @param {*}
 * @return {*}
 */
const downloadFile = (url: string) => {
  window.open(url);
};


//get请求
const get = (url: string, header?: any) => {
  return axios({
    method: 'get',
    url,
    headers: {
      ...(header || {}),
      'Content-Type': 'application/json;charse=UTF-8'
    },
  });
}
//post请求
const post = (url: string, param: any, header?: any) => {
  return axios({
    method: 'post',
    url,
    headers: {
      ...(header || {}),
      'Content-Type': 'application/json;charse=UTF-8'
    },
    data: param || {}
  });
}
//post请求
const put = (url: string, params?: any, header?: any) => {
  return axios({
    method: 'put',
    url,
    headers: {
      ...(header || {}),
      'Content-Type': 'application/json;charse=UTF-8'
    },
    data: params || {}
  });
}
// delete
const delete_ = (url: string, params?: any, header?: any) => {
  return axios({
    method: 'delete',
    url,
    headers: {
      ...(header || {})
    },
    params: params || {}
  });
}
export default {
  get,
  post,
  put,
  delete_,
  downloadFile
};