import type { App } from "vue";
import 'vant/lib/index.css';
import {
  Button,
  Popup,
  Field,
  Picker,
  Tab,
  Tabs,
  Search,
  Collapse,
  CollapseItem,
  Cell,
  CellGroup,
  Step,
  Steps,
  Form,
  TimePicker,
  DatePicker,
  Loading,
  Icon,
  DropdownMenu,
  DropdownItem,
  Dialog,
} from 'vant';

const components = [
  Button,
  Popup,
  Field,
  Picker,
  Tab,
  Tabs,
  Search,
  Collapse,
  CollapseItem,
  Cell,
  CellGroup,
  Step,
  Steps,
  Form,
  TimePicker,
  DatePicker,
  Loading,
  Icon,
  DropdownMenu,
  DropdownItem,
  Dialog,
]

// 注册组件
export function useVantUi(app: App) {
  components.forEach((e) => {
    app.use(e);
  })
}