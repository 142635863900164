import type { Project } from "@/model/project";

class Cache {
  corpId(): string {
    // throw new Error(`please corpId first`);
    return uni.getStorageSync('corpId');
  };
  setCorpId(id: string) {
    uni.setStorageSync('corpId', id);
    // this.corpId = () => { return uni.getStorageSync('corpId'); };
  };
  openId(): string {
    // throw new Error(`please openId first`);
    return uni.getStorageSync('openId');
  };
  setOpenId(id: string) {
    uni.setStorageSync('openId', id);
    // this.openId = () => { return uni.getStorageSync('openId'); };
  };

  passPort(): string {
    // throw new Error(`please passPort first`);
    return uni.getStorageSync('passPort');
  };
  setPassPort(id: string) {
    uni.setStorageSync('passPort', id);
    // this.passPort = () => { return uni.getStorageSync('passPort'); };
  };

  userId(): string {
    // throw new Error(`please setUserId first`);
    return uni.getStorageSync('qwUserId');
  };
  setUserId(id: string) {
    uni.setStorageSync('qwUserId', id);
    // this.userId = () => { return uni.getStorageSync('qwUserId'); };
  };

  userRole(): string {
    // throw new Error(`please setUserRole first`);
    return uni.getStorageSync('userRole');
    // return '2011';
  };
  setUserRole(role: string) {
    uni.setStorageSync('userRole', role);
    // this.userRole = () => { return uni.getStorageSync('userRole'); };
  };

  projects(): Project[] {
    // throw new Error(`please setProjects first`);
    return uni.getStorageSync('projects');
  };
  setProjects(projects: Project[]) {
    uni.setStorageSync('projects', projects);
    // this.projects = () => { return uni.getStorageSync('projects'); };
  };

  projectId(): string {
    // throw new Error(`please setProjectId first`);
    return uni.getStorageSync('projectId');
  };
  setProjectId(id: string) {
    uni.setStorageSync('projectId', id);
    // this.projectId = () => { return uni.getStorageSync('projectId'); };
  };

  projectName(): string {
    // throw new Error(`please setProjectId first`);
    return uni.getStorageSync('projectName');
  };
  setProjectName(val: string) {
    uni.setStorageSync('projectName', val);
    // this.projectId = () => { return uni.getStorageSync('projectId'); };
  };

  clearCache() {
    uni.clearStorage();
  }
}


let cache: Cache;
function init() {
  if (!cache) {
    cache = new Cache();
  }
  return cache;
}
export default init;