import dev from './dev';
import pro from './pro';

async function init() {
  // 本地浏览器开发环境下
  if (import.meta.env.DEV) {
    dev();
    return;
  }
  await pro();
}

export default init