<script lang="jsx">
import Cache from "@/cache";
export default {
  props: {
    text: {
      type: String,
      default: () => {
        return '重新加载'
      }
    }
  },

  setup(props, { emit }) {
    const text = props.text;

    const refresh = () => {
      emit('callback');
    }

    return () => (
      <view class="refresh">
        <view class="inner">
          <view class="text" onClick={() => { refresh() }}>{text}</view>
        </view>
      </view>
    );
  },
};
</script>
<style lang="scss" scoped>
.refresh {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    display: flex;
    flex-direction: column;
    border: 1px solid #999;
    padding: 4px 8px;
    border-radius: 8px;

    .text {
      display: inline-block;
      height: 40;
      line-height: 40rpx;
      letter-spacing: 0.5px;
      font-size: 28rpx;
      color: #999999;
      text-align: center;
    }
  }
}
</style>