<template>
  <view class="more-comp">
    <van-popup v-model:show="showBottom" round position="bottom" :style="{ height: '30%' }">
      <view class="items" v-if="btnList.data.length > 0">
        <view class="item" @click="handleClick(btn)" v-for="btn of btnList.data">
          <van-icon v-if="btn!.iconName" :name="btn!.iconName" />
          <img v-if="btn!.imgUrl" class="img" :src="btn!.imgUrl" alt="" srcset="">
          <view class="text">{{ btn!.title }}</view>
        </view>
      </view>
    </van-popup>
  </view>
</template>
<script   lang="ts">
import { ref, reactive } from 'vue';
class btnClass {
  public title: string = "";
  public iconName: string = "";
  public imgUrl: string = "";
  public eventName: Function = () => { };
}
export default {
  setup() {
    let showBottom = ref(false);
    let btnList = reactive({ data: <btnClass[]>[] });
    const show = (arr: btnClass[]) => {
      showBottom.value = true;
      btnList.data = arr;
    }

    const handleClick = (item: btnClass) => {
      showBottom.value = false;
      item.eventName && item.eventName();
    }

    // 组件暴露的方法
    return {
      showBottom,
      btnList,
      handleClick,
      show
    }
  }
}
</script>
<style scoped lang="scss">
.more-comp {

  .items {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #333;

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 0 12px;

      .text {
        color: #333333;
        font-size: 14px;
        margin-top: 5px;
      }

      img {
        width: 40px;
        height: 40px;
      }

      :deep() {
        .van-icon {
          width: 40px;
          height: 40px;
          border: 1px solid #999;
          text-align: center;
          line-height: 40px;
          border-radius: 50%;
          font-size: 28px;
        }
      }
    }
  }
}
</style>