<script lang="jsx">
export default {
  props: {
    text: {
      type: String,
      default: () => {
        return '加载中...'
      }
    }
  },
  setup(props) {
    const text = props.text;
    return () => (
      <view className="loading-view">
        <van-loading size="24px" vertical>{text}</van-loading>
      </view>
    );
  },
};
</script>
<style lang="scss" scoped>
.loading-view {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>