<script lang="jsx">
export default {
  props: {
    text: {
      type: String,
      default: () => {
        return '暂无数据'
      }
    }
  },
  setup(props) {
    const text = props.text;
    return () => (
      <view class="empty">
        <view class="inner">
          <view class="icon"></view>
          <view class="text">{text}</view>
        </view>
      </view>
    );
  },
};
</script>
<style lang="scss" scoped>
.empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    display: flex;
    flex-direction: column;

    .icon {
      display: inline-block;
      width: 120px;
      height: 120px;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../static/img/empty.png");
    }

    .text {
      display: inline-block;
      height: 40;
      line-height: 40rpx;
      margin-top: 10rpx;
      letter-spacing: 0.5px;
      font-size: 28rpx;
      color: #999999;
      text-align: center;
    }
  }
}
</style>