<script setup lang="ts">
import Cache from "@/cache";
import authInit from "./auth/index";
import { Project } from "@/model/project";
import { getProjectList } from "@/api/common/projectListApi";
import { onLaunch, onShow, onHide } from "@dcloudio/uni-app";
import { postObServerEvent, ProjectLoadEvent } from "@/observer/event"
import { onMounted } from "vue";

onMounted(async () => {
  // Cache().clearCache();
  // 获取用户权限
  await authInit();
  await initProject();
});
onLaunch(async () => { });
onShow(() => { });
onHide(() => { });

async function initProject() {
  let newColumn: Project[] = [];
  const [ret, err] = await getProjectList();
  if (err) return console.error('app initProject error:', err, ret);
  Cache().setUserRole(ret.user.roleid);
  for (let index = 0, element; element = ret.projectIds[index++];) {
    let project = new Project();
    project.id = element.projectIdErp;
    project.name = element.projectname;
    newColumn.push(project);
  };
  Cache().setProjects(newColumn);
  postObServerEvent(new ProjectLoadEvent());
};
</script>
